<template>
	<v-row no-gutters class="search-box d-flex align-center">
		<v-col cols="8">
			<!-- <v-text-field
				single-line
				solo
				prepend-inner-icon="search"
				hide-details
				height="48"
				color="#CACEDA"
				type="text"
				placeholder="Search"
			></v-text-field> -->
		</v-col>

		<v-col cols="4" class="mt-3">
			<div class="d-flex justify-end align-center">
				<span
					class="heading-name pr-4"
				>{{ this.$store.state.auth.user_data.first_name }} {{ this.$store.state.auth.user_data.last_name }}</span>

				<v-avatar color="#BDBDBD">
					<img
						:src="$store.state.icons.icons['user-circle']"
						style="width:70%;height:70%;filter: brightness(0) saturate(100%) invert(98%) sepia(2%)saturate(0%) hue-rotate(231deg) brightness(103%) contrast(102%);"
						alt
					/>
				</v-avatar>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			search: ""
		};
	}
};
</script>


<style lang="scss">
.search-box {
	background: #fbfbfb !important;
	padding: 0px 32px;
	height: 100%;
	width: 100%;
}

.search-box .v-input__slot {
	background: #fbfbfb !important;
	border: none !important;
	box-shadow: none !important;
	margin-bottom: 0px;
}

.search-box .v-input__control > .v-input__slot:focus-within {
	background: #fbfbfb !important;
}

.search-box .v-icon {
	color: #7587a3 !important;
	font-size: 28px !important;
	letter-spacing: 18px !important;
	margin-top: -6px;
}
</style>